import styled from 'styled-components';
import PropTypes from 'prop-types';

import { media } from '../../styles/media';

const Column = styled.div`
  margin-bottom: ${props => props.theme.layout.gutter}px;

  flex: 0 0 ${props => 100 / props.mobile}%;

  width: 100%;
  max-width: ${props => 100 / props.mobile}%;
  min-height: 1px;

  padding-left: ${props => props.space}px;
  padding-right: ${props => props.space}px;

  ${media.tablet`
    flex: 0 0 ${props => 100 / props.tablet}%;

    max-width: ${props => 100 / props.tablet}%;
  `};

  ${media.desktop`
    flex: 0 0 ${props => 100 / props.desktop}%;

    max-width: ${props => 100 / props.desktop}%;
  `};
`;

Column.defaultProps = {
  mobile: 1,
  tablet: 1,
  desktop: 1,
  space: 30
};

Column.propTypes = {
  mobile: PropTypes.number,
  tablet: PropTypes.number,
  desktop: PropTypes.number,
  space: PropTypes.number
};

export default Column;
