import styled from 'styled-components';
import PropTypes from 'prop-types';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${props => -props.space}px;
  margin-right: ${props => -props.space}px;
`;

Row.defaultProps = {
  space: 30
};

Row.propTypes = {
  space: PropTypes.number
};

export default Row;
