import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0 ${props => props.theme.layout.gutter * 0.75}px;
  width: 100%;
  max-width: ${props => props.theme.layout.width}px;
`;

export default Container;
